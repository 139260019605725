<template>
  <Layout el="hmf">
    <Header el="shadow-sm">
      <Text el="h2">Header</Text>
    </Header>
    <main el="main" style="padding: 32px">
      <div style="max-width: 600px">
        <List el="xsm">
          <List el="xsm" style="padding: 32px">
            <Text el="h4">Buttons</Text>
            <hr el />
            <List el="column xsm">
              <Btn>Button</Btn>
              <Btn disabled>Button</Btn>
              <Btn el="rnd ajhscgjsahg">Button</Btn>
              <Btn el="rnd" disabled>Button</Btn>
            </List>
            <List el="column xsm">
              <Btn el="2ry">Button</Btn>
              <Btn el="2ry" disabled>Button</Btn>
              <Btn el="2ry rnd">Button</Btn>
              <Btn el="2ry rnd" disabled>Button</Btn>
            </List>
            <List el="column xsm">
              <Link href="https://google.com">External Link</Link>
              <Link to="/dashboard/resources">Internal Link (SPA)</Link>
            </List>
          </List>
          <List el="xsm" style="padding: 32px">
            <Text el="h4">Inputs</Text>
            <hr el />
            <List el="xsm">
              <Input label="Input" placeholder="Text" />
              <Input type="textarea" label="Textarea" placeholder="Text" />
            </List>
          </List>
          <List el="xsm" style="padding: 32px">
            <Text el="h4">Typography</Text>
            <hr el />
            <List el="xsm">
              <Text el="h1">Heading H1</Text>
              <Text el="h2">Heading H2</Text>
              <Text el="h3">Heading H3</Text>
              <Text el="h4">Heading H4</Text>
              <Text el="h5">Heading H5</Text>
              <Text>Paragraph</Text>
            </List>
          </List>
          <List el="xsm" style="padding: 32px">
            <Text el="h4">Modals</Text>
            <hr el />
            <List el="column xsm">
              <Btn @click="$refs.modalLeft.toggle()">Left Modal</Btn>
              <Btn @click="$refs.modal.toggle()">Center Modal</Btn>
              <Btn @click="$refs.modalRight.toggle()">Right Modal</Btn>
            </List>
          </List>
          <List el="xsm" style="padding: 32px">
            <Text el="h4">Notifications</Text>
            <hr el />
            <List el="column xsm">
              <Alert>info alert</Alert>
              <Alert el="warning">warning alert</Alert>
              <Alert el="error">error alert</Alert>
            </List>
          </List>
        </List>
      </div>
    </main>
    <footer el="footer">footer</footer>
  </Layout>
  <Modal title="Modal Component" ref="modal">
    <List>
      <Text>Paragraph</Text>
    </List>
    <List el="column xxsm" style="justify-content: end">
      <Btn @click="$refs.modal.toggle()">Close</Btn>
    </List>
  </Modal>
  <Modal el="left" title="Modal Component" ref="modalLeft">
    <List>
      <Text>Paragraph</Text>
    </List>
    <List el="column xxsm" style="justify-content: end">
      <Btn @click="$refs.modalLeft.toggle()">Close</Btn>
    </List>
  </Modal>
  <Modal el="right" title="Modal Component" ref="modalRight">
    <List>
      <Text>Paragraph</Text>
    </List>
    <List el="column xxsm" style="justify-content: end">
      <Btn @click="$refs.modalRight.toggle()">Close</Btn>
    </List>
  </Modal>
</template>

<script>
export default {
  components: {
    // Layout,
  },
};
</script>
<style lang="scss">
[el^="header"] {
  --bg-color: #f1f1f1;
}
[el^="footer"] {
  --bg-color: green;
}
</style>
